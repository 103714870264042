import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import Form from '../components/form'

import kv from '../images/recruit-kv.jpg'

const Recruit = ({ location }) => (
  <Layout location={location} title="Recruit" subTitle="採用情報" kv={kv}>
    <SEO title="採用情報" />
    <section className="contact">
      <h2>雇用条件</h2>
      <section className="information apply">
        <dl>
          <dt>給与</dt>
          <dd>月給20万円＋インセンティブ<br />※経験によって変動します</dd>
        </dl>
        <dl>
          <dt>雇用形態</dt>
          <dd>
          正社員<br />業務委託<br />インターン
          </dd>
        </dl>
        <dl>
          <dt>給与等</dt>
          <dd>経験等による為、お問い合わせ下さい</dd>
        </dl>
        <dl>
          <dt>休日・休暇</dt>
          <dd>週休2日　月曜日　火曜日</dd>
        </dl>
        <dl>
          <dt>仕事内容</dt>
          <dd>
          ①営業（太陽光発電システム、蓄電池システム等）<br />②事務（納期管理、資料作成等）
          </dd>
        </dl>
        <dl>
          <dt>勤務地</dt>
          <dd>
          大阪市北区　本社
          </dd>
        </dl>
      </section>
    </section>
    <section className="global-form">
      <h2>応募フォーム</h2>
      <Form showCompany={false} from="採用" />
    </section>
  </Layout>
)

export default Recruit
